/* Base Navbar Styling */
.navbar {
  background: rgba(255, 255, 255, 0.9); /* Translucent white */
  backdrop-filter: blur(10px); /* Smooth blur effect */
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

/* Navbar Container */
.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Logo Styling */
.navbar-logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar-logo-image {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.navbar-logo-text {
  font-size: 1.5em;
  font-weight: bold;
  color: #000;
  text-decoration: none;
  letter-spacing: 1px;
  transition: color 0.3s ease;
}

.navbar-logo-text:hover {
  color: #03A9F4; /* Blue hover effect */
}

.navbar-logo-container:hover .navbar-logo-image {
  transform: scale(1.1);
}

/* Navigation Links */
.navbar-links {
  list-style: none;
  display: flex;
  gap: 30px;
  margin: 0;
  align-items: center;
}

.navbar-link {
  color: #000;
  text-decoration: none;
  font-size: 1em;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background 0.3s ease, color 0.3s ease;
}

.navbar-link:hover {
  color: #fff;
  background: #03A9F4; /* Blue background on hover */
}

/* Hamburger Menu */
.hamburger-icon {
  display: none; /* Hidden on desktop */
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
  z-index: 1001;
}

.hamburger-icon span {
  background-color: #000;
  height: 3px;
  width: 100%;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.hamburger-icon.active span:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.hamburger-icon.active span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.active span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

/* Mobile Menu */
@media (max-width: 768px) {
  .hamburger-icon {
    display: flex; /* Visible on mobile */
    margin-right: 15px;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 18px;
    cursor: pointer;
  }

  .hamburger-icon span {
    background-color: #000; /* Black bars */
    height: 3px;
    width: 100%;
    border-radius: 5px; /* Round corners for bars */
    transition: all 0.3s ease;
  }

  .navbar-links {
    position: fixed;
    top: 10px;
    right: 10px; /* Position it at the top-right */
    background: rgba(255, 255, 255, 0.95); /* Translucent white */
    flex-direction: column;
    align-items: flex-start;
    height: auto; /* Adjust height dynamically */
    width: 250px; /* Fixed width for the pop-out menu */
    transform: translateX(100%); /* Hidden offscreen initially */
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    padding: 20px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Add shadow */
    border-radius: 15px; /* Rounded corners */
    z-index: 1000; /* Ensure it appears above other elements */
    opacity: 0; /* Initially invisible */
    pointer-events: none; /* Prevent clicks when hidden */
  }

  .navbar-links.open {
    transform: translateX(0); /* Slide-in effect */
    opacity: 1; /* Make visible */
    pointer-events: auto; /* Allow clicks */
  }

  .navbar-link {
    font-size: 1.2em;
    width: 100%;
    padding: 15px;
    text-align: left;
    margin: 5px 0;
    border-radius: 10px; /* Rounded corners for links */
    transition: background 0.3s ease, color 0.3s ease;
  }

  .navbar-link:hover {
    background: #03a9f4; /* Blue hover effect */
    color: #fff; /* White text on hover */
  }

  .hamburger-icon:hover span {
    background-color: #03a9f4; /* Blue hover effect for bars */
  }
}
