/* Base Footer Styling */
.footer {
  background: linear-gradient(90deg, #03A9F4, #ffffff); /* Blue-to-white gradient */
  color: #000; /* Black text */
  padding: 30px 50px;
  display: flex;
  justify-content: space-between; /* Align sections horizontally */
  align-items: flex-start; /* Align sections to the top */
  gap: 20px; /* Space between sections */
  box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow at the top */
}

/* Footer Section */
.footer-section {
  flex: 1 1 auto; /* Flexible width for sections */
  margin: 0 20px; /* Add horizontal margin */
  min-width: 250px; /* Ensure sections don’t shrink too small */
}

/* Footer Logo */
.footer-logo {
  font-size: 1.8em;
  font-weight: bold;
  color: #2c546f; /* Blue logo text */
  margin-bottom: 15px;
  text-shadow: 0 2px 4px rgba(3, 169, 244, 0.5); /* Glow effect */
}

/* Section Headings */
.footer-heading {
  font-size: 1.5em;
  color: #2c546f; /* Blue text */
  margin: 10px 0;
  border-bottom: 2px solid #2c546f; /* Blue underline */
  padding-bottom: 5px;
}

/* Footer Links */
.footer-link {
  color: #000; /* Black text */
  text-decoration: none;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.footer-link:hover {
  color: #ffffff; /* White text on hover */
  text-shadow: 0 2px 6px rgba(3, 169, 244, 0.6); /* Glow effect */
}

/* Footer Lists */
.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-list li {
  margin: 10px 0;
}

/* Social Media Icons */
.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}

.social-link {
  color: #2c546f; /* Blue icon color */
  font-size: 1.8em;
  transition: transform 0.3s ease, color 0.3s ease;
  text-shadow: 0 2px 4px rgba(3, 169, 244, 0.5); /* Subtle glow */
}

.social-link:hover {
  color: #ffffff; /* White on hover */
  transform: scale(1.2); /* Slight zoom effect */
  text-shadow: 0 4px 8px rgba(0, 174, 255, 0.8); /* Intense glow */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .footer {
    flex-direction: column; /* Stack sections vertically */
    align-items: flex-start;
    padding: 20px;
  }

  .footer-section {
    width: 100%; /* Full width for each section */
    margin: 15px 0; /* Add vertical spacing */
  }

  .social-icons {
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
  }
}
