/* Global Reset for Light Theme */
html,
body {
  margin: 0;
  padding: 0;
  background-color: #ffffff; /* White background */
  color: #000; /* Black text for contrast */
  font-family: 'Roboto', sans-serif;
  min-height: 100%;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Interactive Container Styling */
.interactive-container {
  position: relative;
  width: 100%;
  height: 200vh; /* 3 courts, 100vh each */
}

/* Text and Blockquote Styling */
.interactive-container h2,
.interactive-container h3,
.interactive-container p,
.interactive-container blockquote {
  margin: 10px 0;
  font-family: 'Roboto', sans-serif;
  color: #000; /* Black text color */
}

.interactive-container blockquote {
  font-size: 1.2em;
  font-style: italic;
  color: #555555; /* Softer contrast for blockquote */
  margin: 20px 0;
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

/* Reset Links */
a {
  color: inherit;
  text-decoration: none;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

/* Tournament Button Styling */
.tournament-button {
  display: inline-block;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  padding: 15px 30px;
  border: 2px solid #03A9F4; /* Blue border */
  background-color: transparent;
  color: #03A9F4; /* Blue text */
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.tournament-button:hover {
  background-color: #03A9F4; /* Blue background */
  color: #ffffff; /* White text */
}

.tournament-button::before {
  content: '→';
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translate(-50%, -50%) rotate(90deg);
  font-size: 1.5em;
  transition: transform 0.3s ease-in-out;
}

.tournament-button:hover::before {
  right: 20px;
  transform: translate(-50%, -50%) rotate(0deg);
}

/* About Container */
.about-container {
  min-height: calc(100vh - 80px);
  padding: 20px;
  background: linear-gradient(180deg, #03A9F4, #ffffff); /* Blue to white gradient */
  color: #000; /* Black text */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.about-title {
  font-size: 2.5em;
  color: #ffffff; /* Blue text */
  text-shadow: 0 4px 8px rgba(3, 169, 244, 0.8); /* Subtle blue glow */
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}

.about-content-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap; /* Responsive wrap */
  gap: 20px;
}

.about-text {
  max-width: 600px;
  text-align: left;
}

.about-content {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #000; /* Black text */
  font-family: 'Roboto', sans-serif;
}

.paddle-image {
  width: 100px;
  height: auto;
  animation: spin 5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
